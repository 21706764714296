@import url('https://fonts.googleapis.com/css2?family=Joan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Joan', serif;
   
}
.nav_section{
    background-color: #0a2049;
    width: auto;
    height: 10vh;
    align-items: center;
    display: flex;
    
}
.topimagesect11{
    /* width: 100vw; */
    height: 85vh;
}
.topimage11{
    background-image: url(../Images/i3.jpg);
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
#nav_section{
    width: 100vw;
    position: fixed;
    z-index: 5;
}
.link_items{
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
}

.bg-img{
    background-image: url('../Images/i4.jpg');
}
.bg-img1{
    background-image: url('../Images/i3.jpg');
}
.bg-img2{
    background-image: url('../Images/i7.JPG');
}
.bg-img3{
    background-image: url('../Images/i9.JPG');
}
.bg-img4{
    background-image: url('../Images/i8.JPG');
}
.carousel_section{

    height: 100vh;  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
 
}

.logo_col{
    height: 6rem;
    background-color: #ffffff;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    z-index: 6;
    position: fixed;
    top: 0%;
    border: 1px solid rgb(245, 245, 245);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.logo-img{
    width: 7rem;
    
}
.logo{
    display: none;
}

.sect1-image{
border: 1px solid transparent;
height: auto;
background-color: rgb(255, 255, 255, 0.8);
position: absolute;
z-index: 2;
top: 50%;
border-radius: 50px;

}

.homebtn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcome{
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 600;
    color: #148b49;
    /* font-family: 'Playfair Display SC', serif; */
    background: none;
}

.title{
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    font-size: 50px;
    /* font-weight: 800; */
    color: #683884;
    margin-bottom: 1rem;
}
.content{
    font-size: 24px;
    font-style: italic;
    padding: 0 3rem;
}

.advice{
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.help{
    height: auto;
    width: auto;
    margin-top: 2rem;
    background-color: #0a2049;
    padding-top: 3rem;
    padding-bottom: 5rem;
}

.icon{
    display: flex;
    justify-content: center; 
    font-size: 5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.icon2{
    display: flex;
    justify-content: center; 
    font-size: 5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #683884;
    
}
#header{
    font-size: 24px;
    font-weight: bold;
}
.icon1{
    display: flex;
    justify-content: center; 
    font-size: 5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #c32227;
    
}

.headSection{
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    font-size: 28px;
    color: white;
}
.topinfo{
    color: white;
    font-size: 16px;
    /* display: flex;
    justify-content: center; */
}
.share{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;

}
.share_img{
    width: 250px;
    display: flex;
    justify-content: center;
}

.imgcol{
    display: flex;
    justify-content: center;  
}

#linkitems{
    color: white;
}

@media (max-width: 800px) {
    .welcome{
        font-size: 26px;
    }
    .title{
        font-size: 28px;
    }
    .topimage11{
        background-image: url(../Images/i4.jpg);
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .logo{
        display: none;
    }
}


.logimg{
    width: 70px;
    border-radius: 50%;
    /* padding: 10px; */
}
.navdiv{
    background-color: #0a2049;
    height: 80px;
}
.navdet{
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    background-color: #0a2049;
    height: 80px;
}
label.loo{
    color: white;
    font-size: 35px;
    line-height: 80px;
    padding: 0 100px;
    font-weight: bold;
}

nav ul{
    float: right;
    /* margin-right: 200px; */
}
nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
}
nav ul li a{
    color: white;
    text-decoration: none;
    font-size: 18px;
    text-transform: capitalize;
    padding: 8px 15px;
    border-radius: 3px;
}
a.active{
    background-color: #1b9bff;
    transition: 0.5s;
}
/* a:hover{
    background: #d0e7f8;
    color: #d0e7f8;
    transition: 0.5s;
} */
.checkbtn{  
    font-size: 24px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    display: none;
    cursor: pointer;
}
#check{
    display: none;
}
@media (max-width: 952px) {
    label.logo{
        font-size: 30px;
        padding-left: 50px;
    }
    nav ul li a{
         font-size: 16px;
    }
}
@media (max-width: 858px) {
    .checkbtn{
        display: block;
    }
     ul{
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 11;
        background: #2c3e50;
        top: 80px;
        left: -100%;
        text-align: center;
        transition: all 0.5s;
    }
    
    nav ul li a{
        font-size: 20px;
    }
    #check:checked ~ ul{
        left: 0;
    } 
    
}

@media (max-width: 700px) {
    
    nav ul li a{
         font-size: 10px;
    }
    label.loo{
        color: white;
        font-size: 35px;
        line-height: 80px;
        padding: 0 10px;
        font-weight: bold;
    }
}