.topimage1{
    background-image: url(../../Images/donate.jpg);
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.underline3{
    width: 18vw;
    height: 2px;
    background-color: #c5222a;
    margin-bottom: 30px;
}
.donateways{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.donateicon{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.payment{
    display: flex;
    justify-content: center;
    font-size: 21px;
}
.underline0{
    width: 10vw;
    height: 2px;
    background-color: #148b49;
    margin-bottom: 30px;
}
.donatesect{
    margin-bottom: 3rem;
}
.weare1{
    padding-top: 5rem;
    font-size: 24px;
    justify-content: center;
    display: flex;
    margin-bottom: 4rem;
}
.donatecont{
    display: flex;
    justify-content: center;
}
@media (max-width: 700px) {
    .topimage1{
        background-image: url(../../Images/donate.jpg);
        height: 80%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .weare1{
        /* padding-top: 5rem; */
        font-size: 24px;
        justify-content: center;
        display: flex;
        margin-bottom: 4rem;
    }
}