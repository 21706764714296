.contact{
    width: auto;
    height: 65vh;
}
.contactsect1{
    background-color: #eff6fc;
    height: 40vh;
}

.sect{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sectimage{
    background-image: url('../../Images/customer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
}

.secttext{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    height: 40vh;
    align-items: center;
    
}
.getintouch{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.cont1{
    border: 1px solid transparent;
    height: 25vh;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0px 1px 2px rgba(192, 192, 192, 0.5);
    background-color: #eff6fc;
    
}
.contents{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
}
.contaicon{
    display: flex;
    justify-content: center;
}

.contahead{
    display: flex;
    justify-content: center; 
    font-size: 18px;
    margin-bottom: 1rem;
}

.number{
    display: flex;
    justify-content: center; 
    margin-bottom: 1rem;
    color: #148b49;
    font-size: 18px;
}
.footer{
    height: 8vh;
    background-color: #0a2049;

}
.copy{
   padding-top: 1rem; 
}

.link{
    
    text-decoration: none;
    color: white;
    font-size: 18px;
}