.galacont{
    margin-top: 2rem;
}
.imagegala{
    /* border-radius: 10px; */
    background-color: #eff6fc;
    padding: 15px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.ala{
    width: 50vw;
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
}