.missionsection{
    width: auto;
    height: auto;
    
}
.corousel{
    padding-top: 4rem;
}
.headline{
    font-size: 24px;
    display: flex;
    justify-content: center;
}

.missionContainer{
    background-color: white;
    margin-top: 2rem;
    
}
.underline{
    width: 7vw;
    height: 4px;
    background-color: #c32227;
    /* margin-bottom: 80px; */
}

.youth{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    height: 60vh;
    align-items: center;
    
}

.youthhead{
    font-size: 24px;
    font-weight: 600;
}
.tag{
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
}
.info{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    align-items: center;
    font-size: 18px;
}
.colmiss1{
    display: flex;
    justify-content: center;
}
.mish{
    margin-top: 1.5rem;
    font-size: 19px;
}

.vish{
    margin-top: 1.5rem;
    font-size: 19px;
}
.vishhead{
    margin-top: 1.5rem;
    font-size: 28px;
}
.mishhead{
    margin-top: 1.5rem;
    font-size: 28px;
}
.slide5image{
    border-radius: 20px;
}
.ima{
    border-radius: 20px;
}
.carousel-control-prev-icon{
   width: 50px;
   height: 50px;
   background-color: #148b49;
   border-radius: 50%;
   border: 1px solid grey;
}
.carousel-control-next-icon{
    width: 50px;
    height: 50px;
    background-color: #148b49;
    border-radius: 50%;
    border: 1px solid grey;
    
 }