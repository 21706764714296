.aboutcontain{
    margin-top: 4rem;
}
.about{
    font-size: 28px;
   margin-top: 3rem;
}
.underline1{
    width: 8vw;
    height: 4px;
    background-color: #148b49;
    margin-bottom: 30px;
}
.topimagesect{
    /* width: 100vw; */
    height: 80vh;
}
.topimage{
    background-image: url(../../Images/about.jpg);
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.tohome{
    text-decoration: none;
    color: white;
    font-size: 20px;
    transition: all 3s;
}
.tohome:hover{
    color: rgb(233, 245, 255);
}
.abouttopdiv{
    padding-top: 10rem;
    margin-bottom: 1rem;
}
.aboutcontdiv{
    color: white;
    font-size: 40px;

}

.weare{
    padding-top: 5rem;
    font-size: 24px;
    justify-content: center;
    display: flex;
}
.underline2{
    width: 4vw;
    height: 4px;
    background-color: #148b49;
}
.underline3{
    width: 18vw;
    height: 2px;
    background-color: #c5222a;
    margin-bottom: 30px;
}
.conten{
    font-size: 18px;
}
.rdsection{
height: auto;
background-color: #eff6fc;
}
.rdcontainer{
    padding-top: 3rem;
    padding-bottom: 4rem;
}
.aim{
    display: flex;
    justify-content: center;
    font-size: 24px;

}
.underline4{
    width: 8vw;
    height: 2px;
    background-color: #c5222a;
    margin-bottom: 5rem;

}
.youthem{
    display: flex;
    justify-content: center;
    font-size: 19px;
    margin-top: 1rem;
}
.spot{
    display: flex;
    justify-content: center;
}
.underline5{
    width: 4vw;
    height: 4px;
    background-color: #148b49;
    margin-bottom: 2rem;
}
.missionstate{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.underline5{
    width: 100px;
    height: 4px;
    background-color: #c5222a;
    margin-bottom: 2rem;
    border-radius: 50%;

}

.underlin{
    width: 50px;
    height: 4px;
    background-color: #0d6efd;
    margin-bottom: 2rem;
    border-radius: 50%;
}
@media (max-width: 700px) {
    .topimage{
        background-image: url(../../Images/about.jpg);
        height: 80%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .weare{
        /* padding-top: 5rem; */
        font-size: 24px;
        justify-content: center;
        display: flex;
    }
}